import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TablePagination } from "@mui/material";
import { format } from "date-fns";
import { FaDownload } from "react-icons/fa";
import { CSVLink } from "react-csv";

function Row(props) {
  const { row } = props;
  console.log("row: ", row);
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="!text-center">
          {row?.UserID}
        </TableCell>
        <TableCell align="right" className="!text-center">
          {/* <span>
                        {props.selectedOption === 'Consultancy Client' && (
                            <p>{row?.Sum}/55</p>
                        )}
                        {props.selectedOption === 'BPO Clients' && (
                            <p>{row?.Sum}/25</p>
                        )}
                    </span> */}
          {row?.Sum}
        </TableCell>
        <TableCell align="right" className="!text-center">
          {format(new Date(row.Date), "dd-MMM-yyyy hh:mm:ss")}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                <b>Feedback Details</b>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Question</b>
                    </TableCell>
                    <TableCell className="!text-left">
                      <b>Rating</b>
                    </TableCell>
                    <TableCell className="!text-center">
                      <b>Details</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.questions.map((question, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ wordWrap: "break-word", maxWidth: "250px" }}
                      >
                        {question?.Question}
                      </TableCell>
                      <TableCell className="!text-left">
                        {question?.Rating_Value}
                      </TableCell>
                      <TableCell align="center">
                        {question?.OthersValue}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function CollapsibleTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    setRows(props.data);
  }, []);

  return (
    <Paper className="max-w-screen-xl md:mx-auto mx-5">
      {rows?.length === 0 || rows === undefined ? (
        <button
          className="text-gray-400 my-6 mx-3 flex items-center cursor-not-allowed gap-2"
          disabled
        >
          <FaDownload /> Export
        </button>
      ) : (
        <CSVLink
          data={rows.flatMap((row) => {
            const formattedDate = new Date(row.Date).toLocaleString();
            const rowData = [
              { userID: row.UserID, date: formattedDate, sum: row.Sum },
              ...row.questions.map((question) => ({
                question: question.Question,
                rating_Value: question.Rating_Value,
                othersValue: question.OthersValue,
              })),
            ];
            return rowData;
          })}
          headers={[
            { label: "UserID", key: "userID" },
            { label: "Total", key: "sum" },
            { label: "Date", key: "date" },
            { label: "Question", key: "question" },
            { label: "Rating", key: "rating_Value" },
            { label: "Details", key: "othersValue" },
          ]}
        >
          <button className="text-blue-400 my-6 mx-3 flex items-center gap-2">
            <FaDownload /> Export
          </button>
        </CSVLink>
      )}
      <TableContainer component={Paper} className=" mx-auto">
        <Table aria-label="collapsible table">
          <TableHead
            style={{
              background:
                "linear-gradient(90deg, rgba(137, 45, 45, 1) 0%, rgba(216, 122, 122, 1) 100%)",
            }}
          >
            <TableRow>
              <TableCell />
              <TableCell className="!text-white !text-center">
                <b>UserId</b>
              </TableCell>
              <TableCell align="right" className="!text-white !text-center">
                <b>Total Rating</b>
              </TableCell>
              <TableCell align="right" className="!text-white !text-center">
                <b>Date Time</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows?.map((row) => (
                            <Row key={row.userId} row={row} selectedOption={props.selectedOption} />
                        ))} */}
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <Row
                  key={row.userId}
                  row={row}
                  selectedOption={props.selectedOption}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
