import React, { Fragment, useState } from "react";
import logo from "../../assets/images/logo.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const categories = ["Admin", "BPO Client", "Consultancy Client", "Both Client"];

const SignUp = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(categories[0]);

  const handleSignUp = async (e) => {
    e.preventDefault();

    const form = e.target;
    const userId = form.userName.value;
    const password = form.password.value;
    let category = selected;
    console.log(category);
    let client_Type_Id = 0;

    if (category === "Admin") {
      client_Type_Id = 1;
    } else if (category === "BPO Client") {
      client_Type_Id = 2;
    } else if (category === "Consultancy Client") {
      client_Type_Id = 3;
    } else if (category === "Both Client") {
      client_Type_Id = 4;
    }

    const currentDate = new Date().toISOString();

    const newUser = {
      userId: userId,
      userPassword: password,
      client_Type_Id: client_Type_Id,
      creator: "admin",
      creationDate: currentDate,
      isActive: true,
      userType: client_Type_Id,
    };

    await axios
      .post(
        "http://www.api.feedbackinfinigent.quadtheoryltd.com/api/home/createUser",
        newUser
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("User successfully registered!");
          navigate("/admin-panel");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error("User ID already exists");
        } else {
          toast.error("An error occurred while registering the user");
        }
      });
  };

  return (
    <div className="flex justify-center items-center h-screen my-8 lg:my-0">
      <div className="lg:w-1/3 mx-auto rounded-md shadow-md border-x-2 border-b-2">
        <div className="flex justify-center">
          <img src={logo} alt="" />
        </div>
        <form onSubmit={handleSignUp} className="card-body">
          <div className="form-control">
            <label className="label">
              <span className="label-text">User Name</span>
            </label>
            <input
              type="text"
              name="userName"
              placeholder="User Name"
              className="input input-bordered"
              required
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Password</span>
            </label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="input input-bordered"
              minLength={4}
              required
            />
          </div>

          {/* headless ui dropdown */}
          <div className="">
            <label className="label">
              <span className="label-text">Select Category</span>
            </label>
            <Listbox value={selected} onChange={setSelected}>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-red-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-red-300 sm:text-sm input input-bordered ">
                  <span className="block truncate">{selected}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    name="category"
                    className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
                  >
                    {categories.map((category, categoryIdx) => (
                      <Listbox.Option
                        key={categoryIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active
                              ? "bg-red-100 text-amber-900"
                              : "text-gray-900"
                          }`
                        }
                        name={category}
                        value={category}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {category}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-red-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>

          <div className="form-control mt-6">
            <button className="btn text-white text-lg bg-[#D04848] hover:bg-[#fa8484] border-none w-full rounded-full">
              SignUp
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default SignUp;
