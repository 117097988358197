import React from "react";

const TitleBar = ({ heading, category }) => {
  return (
    <div className=" pt-5 text-center rounded-lg pb-0 mt-5">
      <p className="uppercase font-semibold text-2xl">{heading}</p>
      <p className="text-xl font-semibold">{category}</p>
    </div>
  );
};

export default TitleBar;
