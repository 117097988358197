import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SubmitButton from "../Shared/SubmitButton";
import { useNavigate } from "react-router-dom";
// import useAxios from '../../hooks/useAxios';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

function Form() {
  const [userDetails, setUserDetails] = useState();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const [othersValue6, setOthersValue6] = useState("");
  const [showInput, setShowInput] = useState(true);

  const onSubmit = (data) => {
    console.log(userDetails);
    console.log("user id", userDetails.userId);
    const currentDate = new Date().toISOString();
    let newFeedback = [
      {
        userId: userDetails.UserId,
        questionId: 1,
        responseRatingId: data.checkboxField1,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 2,
        responseRatingId: data.checkboxField2,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 3,
        responseRatingId: data.checkboxField3,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 4,
        responseRatingId: data.checkboxField4,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 5,
        responseRatingId: data.checkboxField5,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 6,
        responseRatingId: data.checkboxField6,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 7,
        responseRatingId: data.checkboxField7,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 8,
        responseRatingId: data.checkboxField8,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 9,
        responseRatingId: data.checkboxField9,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 10,
        responseRatingId: data.checkboxField10,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 11,
        responseRatingId: data.checkboxField11,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: othersValue6.trim() !== "" ? othersValue6 : "N/A",
      },
    ];
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to submit?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, submit",
      confirmButtonColor: "#882c2c",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios
          .post(
            "http://www.api.feedbackinfinigent.quadtheoryltd.com/api/home/createCFFClientFeedback",
            newFeedback
          )
          .then((response) => {
            // console.log(response);
            if (response.status === 200) {
              console.log("Success");
              toast.success("Feedback submitted successfully");
              reset();
              setOthersValue6("");
            }
          });
      }
    });

    console.log("new", newFeedback);
  };
  useEffect(() => {
    let userDetailOBJ = localStorage.getItem("UserDetails");
    reset();
    if (!userDetailOBJ) {
      navigate("/login");
    } else {
      setUserDetails(JSON.parse(userDetailOBJ));
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-5">
          <p>1. The Scope</p>
          <p className="pl-5 mt-1">
            Was the scope of the work clearly understood by our team?
          </p>
          <div className="mt-4 pl-5">
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio1"
                name="radio1"
                value={"1"}
                className="mr-2"
                {...register("checkboxField1", { required: true })}
              />{" "}
              1{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio1"
                name="radio1"
                value={"2"}
                className="mr-2"
                {...register("checkboxField1", { required: true })}
              />{" "}
              2{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio1"
                name="radio1"
                value={"3"}
                className="mr-2"
                {...register("checkboxField1", { required: true })}
              />{" "}
              3{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio1"
                name="radio1"
                value={"4"}
                className="mr-2"
                {...register("checkboxField1", { required: true })}
              />{" "}
              4{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio1"
                name="radio1"
                value={"5"}
                className="mr-2"
                {...register("checkboxField1", { required: true })}
              />{" "}
              5{" "}
            </label>
            {errors.checkboxField1?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
        </div>
        <div className="mb-5">
          <p>2. Presentation of the report</p>
          <p className="pl-5 mt-1">Was the report well presented?</p>
          <div className="mt-4 pl-5">
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio2"
                name="radio2"
                value={"1"}
                className="mr-2"
                {...register("checkboxField2", { required: true })}
              />{" "}
              1{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio2"
                name="radio2"
                value={"2"}
                className="mr-2"
                {...register("checkboxField2", { required: true })}
              />{" "}
              2{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio2"
                name="radio2"
                value={"3"}
                className="mr-2"
                {...register("checkboxField2", { required: true })}
              />{" "}
              3{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio2"
                name="radio2"
                value={"4"}
                className="mr-2"
                {...register("checkboxField2", { required: true })}
              />{" "}
              4{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio2"
                name="radio2"
                value={"5"}
                className="mr-2"
                {...register("checkboxField2", { required: true })}
              />{" "}
              5{" "}
            </label>
            {errors.checkboxField2?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
          <p className="pl-5 mt-1">
            Could the consultant address the issues /questions raised?
          </p>
          <div className="mt-4 pl-5">
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio3"
                name="radio3"
                value={"1"}
                className="mr-2"
                {...register("checkboxField3", { required: true })}
              />{" "}
              1{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio3"
                name="radio3"
                value={"2"}
                className="mr-2"
                {...register("checkboxField3", { required: true })}
              />{" "}
              2{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio3"
                name="radio3"
                value={"3"}
                className="mr-2"
                {...register("checkboxField3", { required: true })}
              />{" "}
              3{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio3"
                name="radio3"
                value={"4"}
                className="mr-2"
                {...register("checkboxField3", { required: true })}
              />{" "}
              4{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio3"
                name="radio3"
                value={"5"}
                className="mr-2"
                {...register("checkboxField3", { required: true })}
              />{" "}
              5{" "}
            </label>
            {errors.checkboxField3?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
        </div>
        <div className="mb-5">
          <p>3. The Report</p>
          <p className="pl-5 mt-1">Were relevant aspects covered?</p>
          <div className="mt-4 pl-5">
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio4"
                name="radio4"
                value={"1"}
                className="mr-2"
                {...register("checkboxField4", { required: true })}
              />{" "}
              1{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio4"
                name="radio4"
                value={"2"}
                className="mr-2"
                {...register("checkboxField4", { required: true })}
              />{" "}
              2{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio4"
                name="radio4"
                value={"3"}
                className="mr-2"
                {...register("checkboxField4", { required: true })}
              />{" "}
              3{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio4"
                name="radio4"
                value={"4"}
                className="mr-2"
                {...register("checkboxField4", { required: true })}
              />{" "}
              4{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio4"
                name="radio4"
                value={"5"}
                className="mr-2"
                {...register("checkboxField4", { required: true })}
              />{" "}
              5{" "}
            </label>
            {errors.checkboxField4?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
          <p className="pl-5 mt-1">
            Were aspects of the report discussed with you?
          </p>
          <div className="mt-4 pl-5">
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio5"
                name="radio5"
                value={"1"}
                className="mr-2"
                {...register("checkboxField5", { required: true })}
              />{" "}
              1{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio5"
                name="radio5"
                value={"2"}
                className="mr-2"
                {...register("checkboxField5", { required: true })}
              />{" "}
              2{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio5"
                name="radio5"
                value={"3"}
                className="mr-2"
                {...register("checkboxField5", { required: true })}
              />{" "}
              3{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio5"
                name="radio5"
                value={"4"}
                className="mr-2"
                {...register("checkboxField5", { required: true })}
              />{" "}
              4{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio5"
                name="radio5"
                value={"5"}
                className="mr-2"
                {...register("checkboxField5", { required: true })}
              />{" "}
              5{" "}
            </label>
            {errors.checkboxField5?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
          <p className="pl-5 mt-1">Was the report submitted on time?</p>
          <div className="mt-4 pl-5">
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio6"
                name="radio6"
                value={"1"}
                className="mr-2"
                {...register("checkboxField6", { required: true })}
              />{" "}
              1{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio6"
                name="radio6"
                value={"2"}
                className="mr-2"
                {...register("checkboxField6", { required: true })}
              />{" "}
              2{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio6"
                name="radio6"
                value={"3"}
                className="mr-2"
                {...register("checkboxField6", { required: true })}
              />{" "}
              3{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio6"
                name="radio6"
                value={"4"}
                className="mr-2"
                {...register("checkboxField6", { required: true })}
              />{" "}
              4{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio6"
                name="radio6"
                value={"5"}
                className="mr-2"
                {...register("checkboxField6", { required: true })}
              />{" "}
              5{" "}
            </label>
            {errors.checkboxField6?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
        </div>
        <div className="mb-5">
          <p>4. The Consultant</p>
          <p className="pl-5 mt-1">Attend meetings as required</p>
          <div className="mt-4 pl-5">
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio7"
                name="radio7"
                value={"1"}
                className="mr-2"
                {...register("checkboxField7", { required: true })}
              />{" "}
              1{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio7"
                name="radio7"
                value={"2"}
                className="mr-2"
                {...register("checkboxField7", { required: true })}
              />{" "}
              2{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio7"
                name="radio7"
                value={"3"}
                className="mr-2"
                {...register("checkboxField7", { required: true })}
              />{" "}
              3{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio7"
                name="radio7"
                value={"4"}
                className="mr-2"
                {...register("checkboxField7", { required: true })}
              />{" "}
              4{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio7"
                name="radio7"
                value={"5"}
                className="mr-2"
                {...register("checkboxField7", { required: true })}
              />{" "}
              5{" "}
            </label>
            {errors.checkboxField7?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
          <p className="pl-5 mt-1">Discuss details of the work with you</p>
          <div className="mt-4 pl-5">
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio8"
                name="radio8"
                value={"1"}
                className="mr-2"
                {...register("checkboxField8", { required: true })}
              />{" "}
              1{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio8"
                name="radio8"
                value={"2"}
                className="mr-2"
                {...register("checkboxField8", { required: true })}
              />{" "}
              2{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio8"
                name="radio8"
                value={"3"}
                className="mr-2"
                {...register("checkboxField8", { required: true })}
              />{" "}
              3{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio8"
                name="radio8"
                value={"4"}
                className="mr-2"
                {...register("checkboxField8", { required: true })}
              />{" "}
              4{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio8"
                name="radio8"
                value={"5"}
                className="mr-2"
                {...register("checkboxField8", { required: true })}
              />{" "}
              5{" "}
            </label>
            {errors.checkboxField8?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
          <p className="pl-5 mt-1">Was courteous</p>
          <div className="mt-4 pl-5">
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio9"
                name="radio9"
                value={"1"}
                className="mr-2"
                {...register("checkboxField9", { required: true })}
              />{" "}
              1{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio9"
                name="radio9"
                value={"2"}
                className="mr-2"
                {...register("checkboxField9", { required: true })}
              />{" "}
              2{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio9"
                name="radio9"
                value={"3"}
                className="mr-2"
                {...register("checkboxField9", { required: true })}
              />{" "}
              3{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio9"
                name="radio9"
                value={"4"}
                className="mr-2"
                {...register("checkboxField9", { required: true })}
              />{" "}
              4{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio9"
                name="radio9"
                value={"5"}
                className="mr-2"
                {...register("checkboxField9", { required: true })}
              />{" "}
              5{" "}
            </label>
            {errors.checkboxField9?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
          <p className="pl-5 mt-1">
            Respond to queries within an acceptable time
          </p>
          <div className="mt-4 pl-5">
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio0"
                name="radio0"
                value={"1"}
                className="mr-2"
                {...register("checkboxField10", { required: true })}
              />{" "}
              1{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio0"
                name="radio0"
                value={"2"}
                className="mr-2"
                {...register("checkboxField10", { required: true })}
              />{" "}
              2{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio0"
                name="radio0"
                value={"3"}
                className="mr-2"
                {...register("checkboxField10", { required: true })}
              />{" "}
              3{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio0"
                name="radio0"
                value={"4"}
                className="mr-2"
                {...register("checkboxField10", { required: true })}
              />{" "}
              4{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio0"
                name="radio0"
                value={"5"}
                className="mr-2"
                {...register("checkboxField10", { required: true })}
              />{" "}
              5{" "}
            </label>
            {errors.checkboxField10?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
        </div>
        <div className="mb-5">
          <p>5. Rating of the work done under this consultancy</p>
          <div className="mt-4 pl-5">
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio00"
                name="radio00"
                value={"1"}
                className="mr-2"
                {...register("checkboxField11", { required: true })}
              />{" "}
              1{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio00"
                name="radio00"
                value={"2"}
                className="mr-2"
                {...register("checkboxField11", { required: true })}
              />{" "}
              2{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio00"
                name="radio00"
                value={"3"}
                className="mr-2"
                {...register("checkboxField11", { required: true })}
              />{" "}
              3{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio00"
                name="radio00"
                value={"4"}
                className="mr-2"
                {...register("checkboxField11", { required: true })}
              />{" "}
              4{" "}
            </label>
            <label className="mr-6">
              {" "}
              <input
                type="radio"
                id="radio00"
                name="radio00"
                value={"5"}
                className="mr-2"
                {...register("checkboxField11", { required: true })}
              />{" "}
              5{" "}
            </label>
            {errors.checkboxField11?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
        </div>
        <div className="mb-5">
          <p>
            6. Please add anything else which may help us to improve our
            services. (Maximum 500 characters)
          </p>
          {/* <textarea className="border-2 border-black w-full ml-5 mt-4 h-5 " ></textarea> */}
          <textarea
            type="text"
            className="border-2 border-black w-full ml-5 mt-4 h-24 font-normal"
            {...register("inputField11", { required: false })}
            value={othersValue6}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 500) {
                setOthersValue6(inputValue);
              } else {
                // Truncate the input to 80 characters
                const truncatedValue = inputValue.slice(0, 500);
                setOthersValue6(truncatedValue);
              }
            }}
          />
          <span className="flex justify-end text-black-400">
            {othersValue6.length}/500
          </span>
        </div>
        <SubmitButton />
      </form>
    </>
  );
}

export default Form;
