import React from "react";
import { Route, Routes } from "react-router-dom";
// import Home from "../components/Home/Home.jsx"
import Login from "../components/Login/Login.jsx";
import SignUp from "../components/SignUp/SignUp.jsx";
import GiveFeedbackAs from "../components/GiveFeedbackAs/GiveFeedbackAs.jsx";
import Consultancy from "../components/Consultancy/Consultancy.jsx";
import BpoClient from "../components/BpoClient/BpoClient.jsx";
import AdminPanel from "../components/AdminPanel/AdminPanel.jsx";
import AllUsers from "../components/AllUsers/AllUsers.jsx";
import AllForms from "../components/AllForms/AllForms.jsx";

function Pages(props) {
  return (
    <Routes>
      {/* <Route path={"/home"} element={<Home />} /> */}
      <Route path={"/login/:from"} element={<Login />} />
      <Route path={"/login"} element={<Login />} />
      <Route path={"/signup"} element={<SignUp />} />
      <Route path={"/"} element={<GiveFeedbackAs />} />
      <Route path={"/consultancy"} element={<Consultancy />} />
      <Route path={"/bpo-clients"} element={<BpoClient />} />
      <Route path={"/admin-panel"} element={<AdminPanel />} />
      <Route path={"/all-users"} element={<AllUsers />} />
      <Route path={"/all-forms"} element={<AllForms />} />
    </Routes>
    
  );
}
export default Pages;
