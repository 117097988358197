import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SubmitButton from "../Shared/SubmitButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const ClientForm = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const [userDetails, setUserDetails] = useState();
  const navigate = useNavigate();
  const [showInput, setShowInput] = useState(false);
  const [showInput2, setShowInput2] = useState(false);
  const [othersValue2, setOthersValue2] = useState("");
  const [othersValue6, setOthersValue6] = useState("");

  const handleRadioChange = (e) => {
    if (e.target.value === "16") {
      // if no
      setShowInput(true);
    } else {
      setShowInput(false);
    }
  };

  const handleRadioChange2 = (e) => {
    if (e.target.value === "15") {
      // if yes
      setShowInput2(true);
    } else {
      setShowInput2(false);
    }
  };

  const onSubmit = (data) => {
    const currentDate = new Date().toISOString();
    let newFeedback = [
      {
        userId: userDetails.UserId,
        questionId: 1,
        responseRatingId: data.checkboxField1,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 2,
        responseRatingId: data.checkboxField2,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue:
          showInput && othersValue2.trim() !== "" ? othersValue2 : "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 3,
        responseRatingId: data.checkboxField3,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 4,
        responseRatingId: data.checkboxField4,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 5,
        responseRatingId: data.checkboxField5,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 6,
        responseRatingId: data.checkboxField6,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue: "N/A",
      },
      {
        userId: userDetails.UserId,
        questionId: 7,
        responseRatingId: data.checkboxField7,
        creator: userDetails.UserId,
        creationDate: currentDate,
        isActive: true,
        othersvalue:
          showInput2 && othersValue6.trim() !== "" ? othersValue6 : "N/A",
      },
    ];

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to submit?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, submit",
      confirmButtonColor: "#882c2c",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios
          .post(
            "http://www.api.feedbackinfinigent.quadtheoryltd.com/api/home/createBPOClientFeedback",
            newFeedback
          )
          .then((response) => {
            if (response.status === 200) {
              toast.success("Feedback submitted successfully");
              setOthersValue2("");
              setOthersValue6("");
              reset();
              setShowInput(false);
              setShowInput2(true);
            }
          });
      }
    });
    // console.log(data);
  };
  useEffect(() => {
    let userDetailOBJ = localStorage.getItem("UserDetails");
    if (!userDetailOBJ) {
      navigate("/login");
    } else {
      setUserDetails(JSON.parse(userDetailOBJ));
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-5">
          <p className=" mt-1">
            1. On a scale of 1 to 5, how satisfied are you with the overall
            services provided by us?{" "}
          </p>
          <div className="mt-4 pl-5">
            <label className="mr-7">
              <input
                type="radio"
                id="radio1"
                name="radio1"
                value={"1"}
                className="mr-2"
                {...register("checkboxField1", { required: true })}
              />{" "}
              1
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio1"
                name="radio1"
                value={"2"}
                className="mr-2"
                {...register("checkboxField1", { required: true })}
              />{" "}
              2
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio1"
                name="radio1"
                value={"3"}
                className="mr-2"
                {...register("checkboxField1", { required: true })}
              />{" "}
              3
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio1"
                name="radio1"
                value={"4"}
                className="mr-2"
                {...register("checkboxField1", { required: true })}
              />{" "}
              4
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio1"
                name="radio1"
                value={"5"}
                className="mr-2"
                {...register("checkboxField1", { required: true })}
              />{" "}
              5
            </label>
            {errors.checkboxField1?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
        </div>
        <div className="mb-5">
          <p className=" mt-1">
            2. Did our team demonstrate a strong understanding of your specific
            business needs and challenges? <span>(Maximum 500 characters)</span>
          </p>
          <div className="mt-4 pl-5">
            <label className="mr-7">
              <input
                type="radio"
                id="radio2"
                name="radio2"
                value={"15"}
                className="mr-2"
                {...register("checkboxField2", { required: true })}
                onClick={(e) => {
                  handleRadioChange(e);
                }}
              />{" "}
              Yes
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio2"
                name="radio2"
                value={"16"}
                className="mr-2"
                {...register("checkboxField2", { required: true })}
                onClick={(e) => {
                  handleRadioChange(e);
                }}
              />{" "}
              No
            </label>

            {errors.checkboxField2?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
            <p>
              If no, please provide details on areas where improvement is
              needed.
            </p>
          </div>
          {showInput && (
            <div className="pl-5 mt-1">
              <textarea
                type="text"
                value={othersValue2}
                className="border-2 border-black w-full mt-4 h-24 font-normal"
                {...register("textbox1", { required: true })}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 500) {
                    setOthersValue2(inputValue);
                  } else {
                    // Truncate the input to 80 characters
                    const truncatedValue = inputValue.slice(0, 500);
                    setOthersValue2(truncatedValue);
                  }
                }}
              />
              <span className="flex justify-end text-black-400">
                {othersValue2.length}/500
              </span>
              {errors.textbox1?.type === "required" && (
                <p role="alert" className="text-red-500 text-sm mt-3 ">
                  * This field is required
                </p>
              )}
            </div>
          )}
        </div>
        <div className="mb-5">
          <p className=" mt-1">
            3. How would you rate the communication and responsiveness of our
            team throughout the engagement?{" "}
          </p>
          <div className="mt-4 pl-5">
            <label className="mr-7">
              <input
                type="radio"
                id="radio3"
                name="radio3"
                value={"1"}
                className="mr-2"
                {...register("checkboxField3", { required: true })}
              />{" "}
              1
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio3"
                name="radio3"
                value={"2"}
                className="mr-2"
                {...register("checkboxField3", { required: true })}
              />{" "}
              2
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio3"
                name="radio3"
                value={"3"}
                className="mr-2"
                {...register("checkboxField3", { required: true })}
              />{" "}
              3
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio3"
                name="radio3"
                value={"4"}
                className="mr-2"
                {...register("checkboxField3", { required: true })}
              />{" "}
              4
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio3"
                name="radio3"
                value={"5"}
                className="mr-2"
                {...register("checkboxField3", { required: true })}
              />{" "}
              5
            </label>
            {errors.checkboxField3?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
        </div>
        <div className="mb-5">
          <p className=" mt-1">
            4. How satisfied are you with the timeliness of deliverables and
            project milestones?{" "}
          </p>
          <div className="mt-4 pl-5">
            <label className="mr-7">
              <input
                type="radio"
                id="radio4"
                name="radio4"
                value={"1"}
                className="mr-2"
                {...register("checkboxField4", { required: true })}
              />{" "}
              1
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio4"
                name="radio4"
                value={"2"}
                className="mr-2"
                {...register("checkboxField4", { required: true })}
              />{" "}
              2
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio4"
                name="radio4"
                value={"3"}
                className="mr-2"
                {...register("checkboxField4", { required: true })}
              />{" "}
              3
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio4"
                name="radio4"
                value={"4"}
                className="mr-2"
                {...register("checkboxField4", { required: true })}
              />{" "}
              4
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio4"
                name="radio4"
                value={"5"}
                className="mr-2"
                {...register("checkboxField4", { required: true })}
              />{" "}
              5
            </label>
            {errors.checkboxField4?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
        </div>
        <div className="mb-5">
          <p className=" mt-1">
            5. How satisfied are you with the resolution of any issues or
            challenges encountered during the engagement?{" "}
          </p>
          <div className="mt-4 pl-5">
            <label className="mr-7">
              <input
                type="radio"
                id="radio5"
                name="radio5"
                value={"1"}
                className="mr-2"
                {...register("checkboxField5", { required: true })}
              />{" "}
              1
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio5"
                name="radio5"
                value={"2"}
                className="mr-2"
                {...register("checkboxField5", { required: true })}
              />{" "}
              2
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio5"
                name="radio5"
                value={"3"}
                className="mr-2"
                {...register("checkboxField5", { required: true })}
              />{" "}
              3
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio5"
                name="radio5"
                value={"4"}
                className="mr-2"
                {...register("checkboxField5", { required: true })}
              />{" "}
              4
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio5"
                name="radio5"
                value={"5"}
                className="mr-2"
                {...register("checkboxField5", { required: true })}
              />{" "}
              5
            </label>
            {errors.checkboxField5?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
        </div>
        <div className="mb-5">
          <p className="mt-1">
            6. On a scale of 1 to 5, how likely are you to recommend our
            management consultancy services to others?{" "}
          </p>
          <div className="mt-4 pl-5">
            <label className="mr-7">
              <input
                type="radio"
                id="radio6"
                name="radio6"
                value={"1"}
                className="mr-2"
                {...register("checkboxField6", { required: true })}
              />{" "}
              1
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio6"
                name="radio6"
                value={"2"}
                className="mr-2"
                {...register("checkboxField6", { required: true })}
              />{" "}
              2
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio6"
                name="radio6"
                value={"3"}
                className="mr-2"
                {...register("checkboxField6", { required: true })}
              />{" "}
              3
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio6"
                name="radio6"
                value={"4"}
                className="mr-2"
                {...register("checkboxField6", { required: true })}
              />{" "}
              4
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio6"
                name="radio6"
                value={"5"}
                className="mr-2"
                {...register("checkboxField6", { required: true })}
              />{" "}
              5
            </label>
            {errors.checkboxField6?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
          </div>
        </div>
        <div className="mb-5">
          <p className="mt-1">
            7. Are there specific areas or aspects of our services that you
            believe need improvement? <span>(Maximum 500 characters)</span>
          </p>
          <div className="mt-4 pl-5">
            <label className="mr-7">
              <input
                type="radio"
                id="radio2"
                name="radio2"
                value={"15"}
                className="mr-2"
                {...register("checkboxField7", { required: true })}
                onClick={(e) => {
                  handleRadioChange2(e);
                }}
              />{" "}
              Yes
            </label>
            <label className="mr-7">
              <input
                type="radio"
                id="radio2"
                name="radio2"
                value={"16"}
                className="mr-2"
                {...register("checkboxField7", { required: true })}
                onClick={(e) => {
                  handleRadioChange2(e);
                }}
              />{" "}
              No
            </label>
            {errors.checkboxField7?.type === "required" && (
              <p role="alert" className="text-red-500 text-sm mt-3 ">
                * This field is required
              </p>
            )}
            <p>If yes, please specify.</p>
          </div>
          {showInput2 && (
            <div className="pl-5 mt-1">
              <textarea
                type="text"
                value={othersValue6}
                className="border-2 border-black w-full mt-4 h-24 font-normal"
                {...register("textbox2", { required: true })}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 500) {
                    setOthersValue6(inputValue);
                  } else {
                    // Truncate the input to 80 characters
                    const truncatedValue = inputValue.slice(0, 500);
                    setOthersValue6(truncatedValue);
                  }
                }}
              />
              <span className="flex justify-end text-black-400">
                {othersValue6.length}/500
              </span>
              {errors.textbox2?.type === "required" && (
                <p role="alert" className="text-red-500 text-sm mt-3 ">
                  * This field is required
                </p>
              )}
            </div>
          )}
        </div>

        <SubmitButton />
      </form>
    </>
  );
};

export default ClientForm;
