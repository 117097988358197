import React, { useEffect, useState } from "react";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
import { Button } from "primereact/button";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../AdminPanel/AdminPanel.css";
import Swal from "sweetalert2";

const AllUserTable = () => {
  const [products, setProducts] = useState([]);
  const [rows, setRows] = useState([]);
  const [active, setActive] = useState(null);
  // console.log('products', products);

  const handleActiveClick = () => {
    setActive(true);
  };

  const handleInactiveClick = () => {
    setActive(false);
  };
  useEffect(() => {
    loadAllUser();
  }, []);

  const loadAllUser = async () => {
    setRows([]);
    try {
      await axios
        .get(
          "http://www.api.feedbackinfinigent.quadtheoryltd.com/api/home/getAllUsers"
        )
        .then((response) => {
          setProducts(response.data);
          let list = [];
          response.data.map((dt, i) => {
            let obj = {
              id: dt.UserId,
              userType: dt.UserType,
              currentStatus: dt.CurrentStatus,
            };
            list.push(obj);
          });
          setRows(list);
          console.log(response.data);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
      } else {
        // Handle other errors

        console.error("Error:", error);
      }
    }
  };

  const handleOnChange = (e, params) => {
    console.log(e.target.value);
    console.log(params.row);
    params.row.isActive = e.target.value;
    params.row.userId = params.row.id;
    // let userDetailOBJ = localStorage.getItem("UserDetails");

    // userDetailOBJ.IsActive = e.target.value;

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to submit?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, submit",
      confirmButtonColor: "#da7c7c",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            "http://www.api.feedbackinfinigent.quadtheoryltd.com/api/home/updateUserIsActive",
            params.row
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("OKJ");
              loadAllUser();
              toast.success("Status Updated Successfully");
            } else if (response.status === 404) {
              toast.error("User not found");
            }
          });
        loadAllUser();
      } else {
        loadAllUser();
      }
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "UserId",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "userType",
      headerName: "User Type",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "currentStatus",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <select
            className="select w-full max-w-xs"
            onChange={(e) => {
              handleOnChange(e, params);
            }}
          >
            <option value={true} selected={params.value === true}>
              Active
            </option>
            <option value={false} selected={params.value === false}>
              Inactive
            </option>
          </select>
        );
      },
    },
  ];
  return (
    <div className="table-wrapper">
      <ToastContainer />
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 6,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default AllUserTable;
