import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import logo from "./../../assets/images/logo.png";
import Swal from "sweetalert2";
import loader from "./../../assets/images/Loader.gif";

function Login() {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState("");
  const location = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem("UserDetails");
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    const form = e.target;
    const userId = form.userId.value;
    const password = form.password.value;
    setLoading(true);
    await axios
      .get(
        `http://www.api.feedbackinfinigent.quadtheoryltd.com/api/home/getUserByUserIdAndPassword?userId=${userId}&password=${password}&type=${location.from}`
      )
      .then((response) => {
        if (response && response.status === 200) {
          console.log(response.data);
          var obj = JSON.stringify(response.data);
          localStorage.setItem("UserDetails", obj);

          if (location.from === "bpo") {
            toast.success("Login Successful");
            navigate("/bpo-clients");
          } else if (location.from === "admin") {
            toast.success("Login Successful");
            navigate("/admin-panel");
          } else {
            toast.success("Login Successful");
            navigate("/consultancy");
          }
        } else if (response.response && response.response.status === 404) {
          toast.error("User Not Found!");
          navigate("/login/" + location.from);
        } else {
          toast.error("User Not Found!");
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          toast.error("User Not Found!");
          navigate("/login/" + location.from);
        } else {
          // Handle other errors
          toast.error("An error occurred. Please try again later.");
          console.error("Error:", error);
        }
        setLoading(false);
      });
  };

  return (
    <div className="flex justify-center items-center h-screen my-8 lg:my-0">
      <ToastContainer />
      <div className="lg:w-1/3 mx-auto rounded-md shadow-md border-x-2 border-b-2">
        <div className="flex justify-center">
          <img src={logo} alt="" />
        </div>
        <form onSubmit={handleLogin} className="card-body">
          <div className="form-control">
            <label className="label">
              <span className="label-text">User Id</span>
            </label>
            <input
              type="text"
              name="userId"
              placeholder="User Id"
              className="input input-bordered"
              required
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Password</span>
            </label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="input input-bordered"
              required
            />
          </div>
          <div className="form-control mt-6">
            <button
              className="btn text-white text-lg bg-[#D04848] hover:bg-[#fa8484] border-none w-full rounded-full"
              disabled={loading}
            >
              {loading ? (
                <img src={loader} style={{ width: "3rem" }} />
              ) : (
                "LogIn"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
