import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Pages from "./Routes/Pages.js";
import "./css/App.css";
import "./index.css";
import { PrimeReactProvider } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function App() {
  return (
    <div>
      <PrimeReactProvider>
        <Pages />
      </PrimeReactProvider>
    </div>
  );
}

export default App;
