import { useState, useEffect } from "react";

function useDate() {
  const [date, setDate] = useState("");

  useEffect(() => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const today = new Date(); // Get today's date

    const year = today.getFullYear();
    const monthIndex = today.getMonth();
    const monthName = months[monthIndex];
    const day = String(today.getDate()).padStart(2, "0");

    const formattedDate = `${monthName} ${day}, ${year}`;

    setDate(formattedDate);
  }, []); // Empty dependency array to run effect only once on mount

  return date;
}

export default useDate;
