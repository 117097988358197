import React from 'react';

const SubmitButton = () => {
    return (
        <div className='flex justify-end my-6'>
            <button className="border-[#892d2d] hover:bg-[#892d2d] btn btn-outline text-black hover:text-white px-16 h-8 text-base">Submit</button>
        </div>
    );
};

export default SubmitButton;