import React, { useEffect, useState } from "react";
import Header from "../Shared/Header";
import TitleBar from "../Shared/TitleBar";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import CollapsibleTable from "../DataTable/DataTable";

const AllForms = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [datas, setDatas] = useState();
  const [selectedOption, setSelectedOption] = useState("Consultancy Client");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleOnLoadClick();
  }, [selectedOption]);

  const handleOptionSelect = (event) => {
    const selectedCategory = event.target.value;
    setSelectedOption(selectedCategory);
  };

  const handleOnLoadClick = async () => {
    setLoading(true);
    let type = 1;
    switch (selectedOption) {
      case "Consultancy Client":
        type = 2;
        break;
      case "BPO Clients":
        type = 1;
        break;
      default:
        break;
    }
    let fromDate = startDate.toLocaleDateString();
    // let toDate = endDate.toLocaleDateString();
    let toDate = new Date(endDate);
    toDate.setDate(toDate.getDate() + 1);
    toDate = toDate.toLocaleDateString();
    let obj = {
      fromDate: fromDate,
      toDate: toDate,
      type: type,
    };
    await axios
      .post(
        "http://www.api.feedbackinfinigent.quadtheoryltd.com/api/home/GetAllFeedBack",
        obj
      )
      .then((res) => {
        setDatas(res.data);
        setLoading(false);
      });
  };

  const renderSelectedComponent = () => {
    switch (selectedOption) {
      case "Consultancy Client":
        return (
          selectedOption && (
            <CollapsibleTable data={datas} selectedOption={selectedOption} />
          )
        );
      case "BPO Clients":
        return (
          selectedOption && (
            <CollapsibleTable data={datas} selectedOption={selectedOption} />
          )
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Header />
      <div className="lg:flex justify-around items-center mt-4 hidden px-9">
        <div className="dropdown">
          <select
            class="select w-full max-w-xs input input-bordered"
            onChange={handleOptionSelect}
            value={selectedOption}
          >
            <option disabled selected>
              Select Form Category
            </option>
            <option value={"Consultancy Client"}>Consultancy Clients</option>
            <option value={"BPO Clients"}>BPO Clients</option>
          </select>
        </div>
        <div className="flex justify-between gap-10">
          <div className="flex gap-2 items-center mt-4 text-sm font-semibold">
            <h2>From Date: </h2>
            <div className="border p-1 w-52">
              <DatePicker
                className=""
                selected={startDate}
                dateFormat="dd/MM/YYYY"
                onChange={(date) => setStartDate(date)}
              />
            </div>
          </div>
          <div className="flex gap-2 items-center mt-4 text-sm font-semibold">
            <h2>To Date: </h2>
            <div className="border p-1 w-52">
              <DatePicker
                className=""
                selected={endDate}
                dateFormat="dd/MM/YYYY"
                onChange={(date) => setEndDate(date)}
              />
            </div>
          </div>
        </div>
        <div>
          <button
            className="border-[#892d2d] hover:bg-[#892d2d] btn btn-outline text-black hover:text-white px-16 h-8"
            onClick={() => {
              handleOnLoadClick();
            }}
          >
            Load
          </button>
        </div>
      </div>
      <div className="block lg:hidden mt-4 mx-6">
        <div className="flex justify-between">
          <div className="dropdown">
            <select
              class="select w-full max-w-xs input input-bordered"
              onChange={handleOptionSelect}
            >
              <option disabled selected>
                Select Form Category
              </option>
              <option value={"Consultancy Client"} defaultChecked>
                Consultancy Clients
              </option>
              <option value={"BPO Clients"}>BPO Clients</option>
            </select>
          </div>
          <div>
            <button
              className="border-[#892d2d] hover:bg-[#892d2d] btn btn-outline text-black hover:text-white px-16 h-8"
              onClick={() => {
                handleOnLoadClick();
              }}
            >
              Load
            </button>
          </div>
        </div>
        <div className="flex justify-between gap-6">
          <div className="flex gap-2 items-center mt-4 text-xs font-semibold">
            <h2>From Date: </h2>
            <div className="border p-1 w-28">
              <DatePicker
                className="w-28"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
          </div>
          <div className="flex gap-2 items-center mt-4 text-xs font-semibold">
            <h2>To Date: </h2>
            <div className="border p-1 w-28">
              <DatePicker
                className="w-28"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="my-16">
        {/* <TitleBar heading={'all forms'} /> */}
        {!loading && renderSelectedComponent()}
      </div>
    </div>
  );
};

export default AllForms;
