import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FaUser, FaUserSlash } from "react-icons/fa";

const Header = () => {
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    let userDetailOBJ = localStorage.getItem("UserDetails");
    if (!userDetailOBJ) {
    } else {
      setUserDetails(userDetailOBJ);
    }
  }, []);
  return (
    <div className="bg-red-50">
      <div className="navbar w-[95%] mx-auto">
        <div className="flex-1">
          {/* <a href='#' className="btn btn-ghost text-xl">daisyUI</a> */}
          <Link to={"/"}>
            <img src={logo} alt="" className="w-[80px] h-[60px]" />
          </Link>
        </div>
        <div className="bg-none z-10">
          <Link to={"/"}>
            <button className=" text-lg font-bold flex items-center gap-2">
              <FaUser /> {userDetails != null ? "Logout" : "Login"}
            </button>
          </Link>
          {/* logged in user will see logout button */}
          {/* <button className=" text-lg font-bold flex items-center gap-2">
                        <FaUserSlash /> LogOut
                    </button> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
