import React, { useState } from "react";
import Header from "../Shared/Header";
import { Link } from "react-router-dom";
import ConsultancyTable from "./ConsultancyTable";
import ClientTable from "./ClientTable";
import { FaListUl, FaUserPlus, FaUsers } from "react-icons/fa";
import "./AdminPanel.css";

const AdminPanel = () => {
  return (
    <div>
      <Header />
      <div className="flex justify-center items-center lg:h-[calc(100vh-80px)]">
        <div className="">
          <h2 className="text-center font-bold text-4xl mb-16 mt-8 lg:mt-0">
            Admin Dashboard
          </h2>
          <div className="flex flex-col lg:flex-row items-center justify-center gap-16">
            <Link to={"/signup"}>
              <div className="text-center border-x-2 border-b-2 px-10 py-7 rounded-lg shadow-sm w-72 h-40">
                <div className="flex justify-center mb-4">
                  <FaUserPlus size={30} color="#da7c7c" />
                </div>
                <p className=" text-lg mb-2 font-semibold">Create User</p>
              </div>
            </Link>
            <Link to={"/all-users"}>
              <div className="text-center border-x-2 border-b-2 px-10 py-7 rounded-lg shadow-sm w-72 h-40">
                <div className="flex justify-center mb-4">
                  <FaUsers size={30} color="#da7c7c" />
                </div>
                <p className=" text-lg mb-2 font-semibold">
                  User Status Change
                </p>
              </div>
            </Link>
          </div>
          <div className="flex justify-center mt-12 mb-8 lg:mb-0">
            <Link to={"/all-forms"}>
              <div className="text-center border-x-2 border-b-2 px-10 py-7 rounded-lg shadow-sm w-72 h-40">
                <div className="flex justify-center mb-4">
                  <FaListUl size={30} color="#da7c7c" />
                </div>
                <p className=" text-lg mb-2 font-semibold">
                  FeedBack Statement
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
