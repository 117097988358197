import React from 'react';
import Header from '../Shared/Header';
import TitleBar from '../Shared/TitleBar';
import ClientForm from './ClientForm';
import useDate from '../../hooks/useDate';

const BpoClient = () => {

    const date = useDate();

    return (
        <div>
            <Header />
            <div className='w-[85%] mx-auto rounded-lg border-2 mt-10'>
                <TitleBar heading={'client feedback form'} category={'( For BPO Clients )'} />
                <div className='lg:px-12 px-8 text-lg font-bold mt-4'>
                    <div className='flex justify-end'>
                        <h2>Submission Date: {date} </h2>
                    </div>
                    <p className='mt-8'>
                        Thank you for taking time to complete the following survey. We would like to request you to
                        kindly give your feedback about the quality of work which we have done under our
                        contract. Please rate your satisfaction level on a scale of 1 to 5 , as below
                    </p>
                    <p className='mt-6'>
                        1: Poor <br />
                        2: Below satisfactory <br />
                        3: Satisfactory <br />
                        4: Good <br />
                        5: Excellent <br />
                    </p>
                    <div>
                        <ClientForm />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BpoClient;