import React from "react";
import admin from "../../assets/images/admin.png";
import consultancy from "../../assets/images/rate.png";
import bpo from "../../assets/images/client2.png";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tooltip'

const GiveFeedbackAs = () => {
  return (
    <div>
      <div className="flex justify-center items-center lg:h-[calc(100vh-80px)]">
        <div className="">
          <h2 className="text-center font-bold text-4xl mt-8 lg:mt-0 mb-16">
            Give FeedBack As
          </h2>
          <div className="flex flex-col lg:flex-row justify-center items-center gap-16">
            <Link to="/login/consultancy">
              <div className="text-center border-x-2 border-b-2 px-10 py-7 rounded-lg shadow-sm w-72"
                // data-tooltip-id="my-tooltip"
                // data-tooltip-content="Log in as Consultancy Client"
                // data-tooltip-variant="error"
              >
                <div className="flex justify-center mb-4">
                  <img src={consultancy} alt="" className="w-[60px] h-[60px]" />
                </div>
                <p className=" text-lg mb-2">
                  <span className="font-bold">Consultancy</span> Client <br /> Feedback Form
                </p>
                {/* <p>
                  ( For <span className="font-bold">Consultancy</span> Clients )
                </p> */}
              </div>
            </Link>
            <Link to="/login/bpo">
              <div className="text-center border-x-2 border-b-2 px-10 py-7 rounded-lg shadow-sm w-72">
                <div className="flex justify-center mb-4">
                  <img src={bpo} alt="" className="w-[60px] h-[60px]" />
                </div>
                <p className=" text-lg mb-2">
                  <span className="font-bold">BPO</span> Client <br /> Feedback Form
                </p>
                {/* <p>
                  ( For <span className="font-bold">BPO</span> Clients )
                </p> */}
              </div>
            </Link>
          </div>
          <div className="flex justify-center mt-12 mb-8 lg:mb-0">
            <Link to={"/login/admin"}>
              <div className="text-center border-x-2 border-b-2 px-10 py-7 rounded-lg shadow-sm w-72 tooltip tooltip-bottom" 
              // data-tip="Log in as Admin"
              >
                <div className="flex justify-center mb-4">
                  <img src={admin} alt="" className="w-[60px] h-[60px]" />
                </div>
                <p className="pb-5 text-lg mb-2">
                  <span className="font-bold">Admin</span> Dashboard
                </p>
                {/* <p>
                  ( For <span className="font-bold">Admin</span> )
                </p> */}
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* <Tooltip
        id="my-tooltip"
        // style={{ backgroundColor: "#665ef7", color: "#222" }}
      /> */}
    </div>
  );
};

export default GiveFeedbackAs;
