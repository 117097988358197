import React, { useState } from "react";
import Header from "../Shared/Header";
import TitleBar from "../Shared/TitleBar";
import AllUserTable from "./AllUserTable";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const AllUsers = () => {
  const [active, setActive] = useState(null);

  const handleActiveClick = () => {
    setActive(true);
  };

  const handleInactiveClick = () => {
    setActive(false);
  };

  return (
    <div>
      <Header />
      <TitleBar heading={"all users"} />
      <Breadcrumbs
        aria-label="breadcrumb"
        // style={{ display: "flex", justifyContent: "end" }}
        className="w-[92%] py-5 lg:py-0 flex justify-center lg:justify-end"
      >
        <Link underline="hover" color="inherit" href="/admin-panel">
          Admin Panel
        </Link>
        <Typography key="3" color="text.primary">
          User Status Change
        </Typography>
      </Breadcrumbs>
      <div className="w-[85%] mx-auto rounded-lg border-2 mt-10">
        <AllUserTable />
      </div>
    </div>
  );
};

export default AllUsers;
